import { theme } from '@hatchddigital/livestock-pricing-shared-utils';
import { elementTheme } from './element-theme';

// Convert font sizes to rem
const fontSizes = Object.entries(theme.fontSizes).reduce(
  (prev, current) => ({
    ...prev,
    [current[0]]: `${parseFloat(current[1]) / 16}rem`,
  }),
  {}
) as typeof theme.fontSizes;

// Sizing of common UI elements
const sizes = {
  header: '64px',
  marketplaceHeader: '112px',
  navItem: '56px',
  nav: '240px',
  vh: 'var(--vh, 1vh)',
  get fullHeight() {
    return `100vh`;
  },
};

// Transitions
const transitions = {
  fast: '0.1s ease-in-out',
  inOutBack: '0.25s cubic-bezier(0.68,-0.55,0.27,1.55);',
};

// Z-indices
const zIndices = {
  navbar: 21,
  nav: 10,
  dialog: 20,
  tooltip: 30,
  listBox: 2,
  inputMessage: 25,
  stockbookHeader: 3,
};

// Colors
const colors = {
  ...theme.colors,
  red: '#ff0000',
  amber: '#cfa213',
  lightRed: '#FA8072',
  lightGray: '#F3F3F3',
  highlightBar: '#00e7e8',
  darkRed: '#b60101',
};

// Border radius
const radii = {
  ...theme.radii,
  rounded: '100%',
};

/**
 * This should be the primary method of accessing theme variables, some properties are modified/extended on from the original theme in the shared library
 */
export const globalTheme = {
  ...theme,
  colors,
  elements: elementTheme,
  fontSizes,
  fontFamily: `'Inter', sans-serif`,
  sizes,
  transitions,
  zIndices,
  radii,
};

import {
  APIApi,
  Configuration,
  ConfigurationParameters,
  FilesApi,
  MarketplaceOrgListingApi,
  OrganisationAddressApi,
  OrganisationAdminUsersApi,
  OrganisationApi,
  OrganisationBookingApi,
  OrganisationContactApi,
  OrganisationMediaApi,
  OrganisationPICApi,
  OrganisationPICAttributeApi,
  OrganisationPrivateGroupApi,
  OrganisationPrivateGroupContactApi,
  OrganisationSubscriptionApi,
  OrganisationSummaryGridApi,
  ProductsApi,
  SearchApi,
  UserApi,
  UserAuthenticationApi,
  UserRegisterApi,
  OrganisationBookingStockApi,
  OrganisationBookingConfirmationApi,
  PublicMarketplaceListingApi,
  OrganisationReportsApi,
  PublicBookingConfirmationApi,
  MarketplaceConfigApi,
  MarketplaceOrgListingEnquiryApi,
  PublicMarketplaceAlertsApi,
} from 'lib/api/generated';
import { useStore } from 'lib/store';

// TODO: Add axios response interceptor to handle retry after auth error?
// https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token

function prefixApiKey(key: string) {
  return `Bearer ${key}`;
}

function prefixApiKeyAuth(key: string) {
  return `Api-Key ${key}`;
}

/**
 * General API client which bundles all relevant API methods and includes the api key / token by default
 */
export class ApiClient {
  protected config: Configuration | undefined;

  // Configure the API default options to include the token from the store otherwise passed in value
  constructor(
    configParams?: Pick<ConfigurationParameters, 'apiKey' | 'basePath'>,
    setAuthKey?: boolean
  ) {
    let apiKey;
    const storeToken = useStore.getState().accessToken;

    // Prefer token from the zustand store
    if (storeToken) {
      apiKey = !setAuthKey
        ? prefixApiKey(storeToken)
        : prefixApiKeyAuth(storeToken);
    }

    // Optionally override with passed in value
    if (configParams?.apiKey && typeof configParams.apiKey === 'string') {
      apiKey = !setAuthKey
        ? prefixApiKey(configParams.apiKey)
        : prefixApiKeyAuth(configParams.apiKey);
    }

    // Set up the config object
    const config = new Configuration({
      basePath: process.env.NEXT_PUBLIC_API_BASE_PATH,
      ...configParams,
      apiKey,
    });

    this.config = config;
  }

  // Authentication API
  get authenticationApi() {
    return new UserAuthenticationApi(this.config);
  }

  // Registration API
  get registerApi() {
    return new UserRegisterApi(this.config);
  }

  // User API
  get userApi() {
    return new UserApi(this.config);
  }

  // Groups API
  get organisationApi() {
    return new OrganisationApi(this.config);
  }

  // Private Groups API
  get privateGroupApi() {
    return new OrganisationPrivateGroupApi(this.config);
  }

  // Contact API
  get contactApi() {
    return new OrganisationContactApi(this.config);
  }

  // Private Groups Contact API
  get privateGroupContactApi() {
    return new OrganisationPrivateGroupContactApi(this.config);
  }

  // Products API
  get productsApi() {
    return new ProductsApi(this.config);
  }

  // Address API
  public get addressApi() {
    return new OrganisationAddressApi(this.config);
  }

  // General API
  get api() {
    return new APIApi(this.config);
  }

  // Admin Users API
  get adminUsersApi() {
    return new OrganisationAdminUsersApi(this.config);
  }

  // Subscription Plan API
  get subscriptionPlanApi() {
    return new OrganisationSubscriptionApi(this.config);
  }

  // Subscription API
  get subscriptionApi() {
    return new OrganisationSubscriptionApi(this.config);
  }

  // Summary Grid API
  get summaryGridApi() {
    return new OrganisationSummaryGridApi(this.config);
  }

  // Bookings API
  get bookingsApi() {
    return new OrganisationBookingApi(this.config);
  }

  // Files API
  get filesApi() {
    return new FilesApi(this.config);
  }

  // Default API
  get defaultApi() {
    return new APIApi(this.config);
  }
  // Listings API(Org)
  get listingApi() {
    return new MarketplaceOrgListingApi(this.config);
  }

  // MeketplaceListing API(Public)
  get marketplacePublicListingApi() {
    return new PublicMarketplaceListingApi(this.config);
  }

  // Reports API
  get reportsApi() {
    return new OrganisationReportsApi(this.config);
  }

  get mediaSignatureApi() {
    return new OrganisationMediaApi(this.config);
  }

  //Search API
  get SearchApi() {
    return new SearchApi(this.config);
  }

  get OrganisationPICApi() {
    return new OrganisationPICApi(this.config);
  }

  get OrganisationPICAttributeApi() {
    return new OrganisationPICAttributeApi(this.config);
  }

  get OrganisationBookingStockApi() {
    return new OrganisationBookingStockApi(this.config);
  }

  get OrganisationBookingConfirmationApi() {
    return new OrganisationBookingConfirmationApi(this.config);
  }

  get BookingPublicConfirmationApi() {
    return new PublicBookingConfirmationApi(this.config);
  }

  // Marketplace config
  get MarketplaceConfigApi() {
    return new MarketplaceConfigApi(this.config);
  }

  // Marketplace config
  get ListingAlertApi() {
    return new PublicMarketplaceAlertsApi(this.config);
  }

  // Enquiry
  get EnquiryApi() {
    return new MarketplaceOrgListingEnquiryApi(this.config);
  }
}

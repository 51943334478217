import styled, { CSSObject, CSSProperties } from 'styled-components';
import { globalTheme } from 'styles/global-theme';

export interface BoxProps {
  color?: keyof typeof globalTheme.colors;
  backgroundColor?: keyof typeof globalTheme.colors;
  padding?: keyof typeof globalTheme.space;
  maxWidth?: CSSProperties['maxWidth'];
}

const getPropStyles = (props: BoxProps): CSSObject => ({
  padding:
    (props.padding !== undefined && globalTheme.space[props.padding]) || 0,
  color:
    (props.color && globalTheme.colors[props.color]) ||
    globalTheme.colors.darkGrey,
  backgroundColor:
    (props.backgroundColor && globalTheme.colors[props.backgroundColor]) ||
    'transparent',
  maxWidth: props.maxWidth,
});

export const Box = styled.div<BoxProps>`
  ${getPropStyles}

  width: 100%;

  * {
    color: inherit;
  }
`;

/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { BookingConfirmation } from '../model';
// @ts-ignore
import { BookingConfirmationSMSPreview } from '../model';
// @ts-ignore
import { PaginatedBookingConfirmationList } from '../model';
/**
 * OrganisationBookingConfirmationApi - axios parameter creator
 * @export
 */
export const OrganisationBookingConfirmationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a Confirmation and sends out an SMS to the recipient of the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationCreate: async (bookingId: string, buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingConfirmationCreate', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingConfirmationCreate', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}/confirmation`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a list of all the Confirmations associated with the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationList: async (bookingId: string, buyerOrgId: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingConfirmationList', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingConfirmationList', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}/confirmation`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details of a single Confirmation
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {string} confirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationRetrieve: async (bookingId: string, buyerOrgId: string, confirmationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingConfirmationRetrieve', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingConfirmationRetrieve', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'confirmationId' is not null or undefined
            assertParamExists('orgBookingConfirmationRetrieve', 'confirmationId', confirmationId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}/confirmation/{confirmation_id}`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"confirmation_id"}}`, encodeURIComponent(String(confirmationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a preview of the SMS content and the recipients details
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationSmsPreviewRetrieve: async (bookingId: string, buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingConfirmationSmsPreviewRetrieve', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingConfirmationSmsPreviewRetrieve', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}/confirmation/sms-preview`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationBookingConfirmationApi - functional programming interface
 * @export
 */
export const OrganisationBookingConfirmationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationBookingConfirmationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a Confirmation and sends out an SMS to the recipient of the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingConfirmationCreate(bookingId: string, buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingConfirmation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingConfirmationCreate(bookingId, buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a list of all the Confirmations associated with the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingConfirmationList(bookingId: string, buyerOrgId: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedBookingConfirmationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingConfirmationList(bookingId, buyerOrgId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the details of a single Confirmation
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {string} confirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingConfirmationRetrieve(bookingId: string, buyerOrgId: string, confirmationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingConfirmation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingConfirmationRetrieve(bookingId, buyerOrgId, confirmationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a preview of the SMS content and the recipients details
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingConfirmationSmsPreviewRetrieve(bookingId: string, buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingConfirmationSMSPreview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingConfirmationSmsPreviewRetrieve(bookingId, buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationBookingConfirmationApi - factory interface
 * @export
 */
export const OrganisationBookingConfirmationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationBookingConfirmationApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a Confirmation and sends out an SMS to the recipient of the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationCreate(bookingId: string, buyerOrgId: string, options?: any): AxiosPromise<BookingConfirmation> {
            return localVarFp.orgBookingConfirmationCreate(bookingId, buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a list of all the Confirmations associated with the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationList(bookingId: string, buyerOrgId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedBookingConfirmationList> {
            return localVarFp.orgBookingConfirmationList(bookingId, buyerOrgId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details of a single Confirmation
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {string} confirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationRetrieve(bookingId: string, buyerOrgId: string, confirmationId: string, options?: any): AxiosPromise<BookingConfirmation> {
            return localVarFp.orgBookingConfirmationRetrieve(bookingId, buyerOrgId, confirmationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a preview of the SMS content and the recipients details
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationSmsPreviewRetrieve(bookingId: string, buyerOrgId: string, options?: any): AxiosPromise<BookingConfirmationSMSPreview> {
            return localVarFp.orgBookingConfirmationSmsPreviewRetrieve(bookingId, buyerOrgId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgBookingConfirmationCreate operation in OrganisationBookingConfirmationApi.
 * @export
 * @interface OrganisationBookingConfirmationApiOrgBookingConfirmationCreateRequest
 */
export interface OrganisationBookingConfirmationApiOrgBookingConfirmationCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationApiOrgBookingConfirmationCreate
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationApiOrgBookingConfirmationCreate
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgBookingConfirmationList operation in OrganisationBookingConfirmationApi.
 * @export
 * @interface OrganisationBookingConfirmationApiOrgBookingConfirmationListRequest
 */
export interface OrganisationBookingConfirmationApiOrgBookingConfirmationListRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationApiOrgBookingConfirmationList
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationApiOrgBookingConfirmationList
     */
    readonly buyerOrgId: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof OrganisationBookingConfirmationApiOrgBookingConfirmationList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrganisationBookingConfirmationApiOrgBookingConfirmationList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for orgBookingConfirmationRetrieve operation in OrganisationBookingConfirmationApi.
 * @export
 * @interface OrganisationBookingConfirmationApiOrgBookingConfirmationRetrieveRequest
 */
export interface OrganisationBookingConfirmationApiOrgBookingConfirmationRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationApiOrgBookingConfirmationRetrieve
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationApiOrgBookingConfirmationRetrieve
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationApiOrgBookingConfirmationRetrieve
     */
    readonly confirmationId: string
}

/**
 * Request parameters for orgBookingConfirmationSmsPreviewRetrieve operation in OrganisationBookingConfirmationApi.
 * @export
 * @interface OrganisationBookingConfirmationApiOrgBookingConfirmationSmsPreviewRetrieveRequest
 */
export interface OrganisationBookingConfirmationApiOrgBookingConfirmationSmsPreviewRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationApiOrgBookingConfirmationSmsPreviewRetrieve
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationApiOrgBookingConfirmationSmsPreviewRetrieve
     */
    readonly buyerOrgId: string
}

/**
 * OrganisationBookingConfirmationApi - object-oriented interface
 * @export
 * @class OrganisationBookingConfirmationApi
 * @extends {BaseAPI}
 */
export class OrganisationBookingConfirmationApi extends BaseAPI {
    /**
     * 
     * @summary Creates a Confirmation and sends out an SMS to the recipient of the Booking
     * @param {OrganisationBookingConfirmationApiOrgBookingConfirmationCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingConfirmationApi
     */
    public orgBookingConfirmationCreate(requestParameters: OrganisationBookingConfirmationApiOrgBookingConfirmationCreateRequest, options?: any) {
        return OrganisationBookingConfirmationApiFp(this.configuration).orgBookingConfirmationCreate(requestParameters.bookingId, requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a list of all the Confirmations associated with the Booking
     * @param {OrganisationBookingConfirmationApiOrgBookingConfirmationListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingConfirmationApi
     */
    public orgBookingConfirmationList(requestParameters: OrganisationBookingConfirmationApiOrgBookingConfirmationListRequest, options?: any) {
        return OrganisationBookingConfirmationApiFp(this.configuration).orgBookingConfirmationList(requestParameters.bookingId, requestParameters.buyerOrgId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the details of a single Confirmation
     * @param {OrganisationBookingConfirmationApiOrgBookingConfirmationRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingConfirmationApi
     */
    public orgBookingConfirmationRetrieve(requestParameters: OrganisationBookingConfirmationApiOrgBookingConfirmationRetrieveRequest, options?: any) {
        return OrganisationBookingConfirmationApiFp(this.configuration).orgBookingConfirmationRetrieve(requestParameters.bookingId, requestParameters.buyerOrgId, requestParameters.confirmationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a preview of the SMS content and the recipients details
     * @param {OrganisationBookingConfirmationApiOrgBookingConfirmationSmsPreviewRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingConfirmationApi
     */
    public orgBookingConfirmationSmsPreviewRetrieve(requestParameters: OrganisationBookingConfirmationApiOrgBookingConfirmationSmsPreviewRetrieveRequest, options?: any) {
        return OrganisationBookingConfirmationApiFp(this.configuration).orgBookingConfirmationSmsPreviewRetrieve(requestParameters.bookingId, requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }
}
